/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var wishlist = [];

function restoreWishlistButtonState() {
    $(".addWishlistBtn").html(button_add_to_wishlist_initial_label);
    $(".addWishlistBtn")
        .removeClass("existToWishlistButton")
        .addClass("addToWishlistButton");
    $(".addWishlistBtn")
        .attr("onclick", button_add_to_wishlist_initial_click_event)
        .attr("href", "#");
}

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist(fromSmallBasket = false) {
    var btn = $(this);
    var $alertBox;
    var idElt = btn.data('idelt');
    var productId = btn.data('productid');
    var productRef = btn.data('product-ref');
    var colorId  = "";
    var sizeId  = "";


    if (fromSmallBasket === true) {
        colorId = btn.data('color-id');
        sizeId = btn.data('size-id');
    } else {
        colorId = $('#couleurProd_' + idElt).length === 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
        sizeId = $('#tailleProd_' + idElt).length === 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    }

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };

    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + create_link('ajax_add_to_wishlist'),
            type: 'post',
            data: datas
        })
        .done(function (resp) {
            var $alertboxLogin = $("#abox");
            $alertboxLogin.addClass('box_wishlist');

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        '<div id="abox">' + html + "</div>"
                    );
                }

                if (resp.message === "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length === 0) {
                        $alertboxLogin
                            .append($(
                                '<div class="btn_container btn_cnx">' +
                                '<button class="button dark"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\');">' +
                                '<span>' +
                                resp.result.btn_connexion +
                                '</span>' +
                                '</button>' +
                                '</div>')
                            );

                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                '<div class="box_wishlist_content">' +
                                resp.result.error_message +
                                '</div>'
                            );
                    }

                    // On cache le bouton original
                    $('#abox .form_submit').css('display', 'none');
                    $alertBox = $alertboxLogin;

                    openMultiShad('abox');
                    $alertBox
                        .appendTo("body")
                        .show();
                } else {
                    if (resp.result.error_message) {
                        alert(resp.result.error_message);
                    } else {
                        alert(Translator.translate('error_wishlist_' + resp.message.toLowerCase()));
                    }
                }
            } else {
                btn.addClass("existToWishlistButton");
                btn.find('.bfr.bgSprite.wishlist').removeClass("wishlist").addClass('wishlistFull');
                wishlist.push(resp.result.wishlist_row_array);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
                changeBtnAddWishlist(true, productRef);

                // If is from basket rollover --> Hide basket rollover in order to display the wishlist rollover beneath
                if (fromSmallBasket) {
                    $('#cart_top.active_rollover').removeClass('active_rollover');
                    $('#cart_top .rollover_bloc').fadeOut(200);
                    // When it is hidden, show it again a bit later, it will not be visible and it will do the trick
                    if ($('#wishlist_list .holder_scrollable').length) {
                        $('#wishlist_list .holder_scrollable').overlayScrollbars({
                            scrollbars: {
                                autoHide: "leave",
                                autoHideDelay: 0,
                            }
                        });
                    }
                    setTimeout(function() {
                        $('#cart_top .rollover_bloc').show();
                    }, 300);
                }
            }
        });
    } else {
        $.ajax({
            url: path_relative_root + "ajax_remove_from_wishlist.php",
            type: 'post',
            data: datas
        })
            .done(function (resp) {
                if (!resp.error) {
                    btn.removeClass("existToWishlistButton");
                    btn.find('.bfr.bgSprite.wishlistFull').removeClass("wishlistFull").addClass('wishlist');

                    if ($('.active_rollover').length) {
                        $('.active_rollover').removeClass('active_rollover');
                    }
                    // When it is hidden, show it again a bit later, it will not be visible and it will do the trick
                    if ($('#wishlist_list .holder_scrollable').length) {
                        $('#wishlist_list .holder_scrollable').overlayScrollbars({
                            scrollbars: {
                                autoHide: "leave",
                                autoHideDelay: 0,
                            }
                        });
                    }
                    ajax_RemoveWishlistProd(productId);
                }

            });
    }
}

function addWishNoConnect(div_id, user, reference, is_alliance, code_couleur, idprod, wishlist) {
    if (user) {
        ajax_achat_express(reference, is_alliance, code_couleur, idprod, wishlist);
    } else {
        addWishListNoConnect(div_id);
    }
}

/** Permet de checker si le produit existe sur la wishlist
 *
 * @param productId : ID produit
 * @param elem : ID produit + ID regroupement
 * @returns {boolean}
 */
function checkProductInWishlist(productId, elem) {

    var colorId = sizeId = 0;
    var couleurProdElt = $("#couleurProd" + elem);
    var tailleProdElt = $("#tailleProd" + elem);

    if (couleurProdElt.length) {
        colorId = couleurProdElt.val();
    } else if ($("#couleurProd")) {
        colorId = $("#couleurProd").val();
    }

    if (tailleProdElt.length) {
        sizeId = tailleProdElt.val();
    } else if ($("#tailleProd")) {
        sizeId = $("#tailleProd").val();
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: {'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true, elem);
                    return true;
                } else {
                    changeBtnAddWishlist(false, elem);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false, elem);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 *
 * @param in_wishlist : true ou false selon si dans wishlist
 * @param elem : ID produit + ID du regroupement
 */
function changeBtnAddWishlist(in_wishlist, elem) {

    var btn = $("#addToWishlistButton" + elem);

    if (!btn.length) {
        btn = $("#addToWishlistButton");
    }

    if (in_wishlist) {
        btn.addClass("existToWishlistButton");
        btn.find('.bfr.bgSprite.wishlist').removeClass("wishlist").addClass('wishlistFull');
    } else {
        btn.removeClass("existToWishlistButton");
        btn.find('.bfr.bgSprite.wishlistFull').removeClass("wishlistFull").addClass('wishlist');
    }
}

function wishlistRedirectLogin() {
    $.get(
        path_relative_root + "ajax_add_to_wishlist.php?login_required=1",
        function () {
            document.location.href = path_relative_root + create_link("connexion_login") + "?from=front";
        }
    );
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html('(' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $(".wrapper_menu_compte_container .item.wishlist").css({'display': 'block'});

        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(' + nr + ')');
    }

    var response_html = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#show_top_wish").replaceWith(response_html);

    displayWishlistRollover();
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        /**
         * Sélectionne le bloc .product_ctn
         */
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            /**
             * Ajoutez la classe
             * 'checked'
             */
            productCnt.addClass('checked');
        } else {
            /**
             * Retirez la classe
             * 'checked'
             */
            if (productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0];
            priceSpan.html(
                '<span id="total_price">0<span class="decimal_price"></span></span><span class="devise">&euro;</span>'
            );
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == "") text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = "";
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = "." + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + "0";
                }
            }
            priceSpan.html(
                '<span id="total_price">' +
                pricefloor +
                '<span class="decimal_price">' +
                decimal +
                '</span></span><span class="devise">&euro;</span>'
            );
            txt =
                '<span class="wishlist_count">' +
                selectedProducts.length +
                '</span> ' +
                text;
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}

/**
 * Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
 * Utilisation : wishlist_index.php
 */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

//Function to load previous selected Size product in basket
function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');
    $.ajax({
        type: 'post',
        url: path_relative_root + create_link("ajax_size_selected"),
        success: function (response) {
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);

                var current_element;
                var sizeProd = document.getElementById("tailleProd");

                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    }
                }
            }
        }
    });
}

/**
 * Allows you to add a product to the wishlist via the ray, search or hover basket
 */
function addToWishlistRay(user = null, fromSmallBasket = false) {
    addToWishlist.call(this, fromSmallBasket);
}

function remove_product_from_wishlist(id, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), {id: id}, callback);
}

function ajax_RemoveWishlistProd(id) {
    remove_product_from_wishlist(id, function (data) {
        if (data.result) {
            var wishlistLength = data.result.wishlistLength;
            addToWishlistUpdateHeader(wishlistLength);
            $('.remove_prod.wishlist_remove[data-id=' + id + ']').parents('.product-link').remove();
            if (wishlistLength < 1) {
                $('#wishlist_top .title_panier').remove();
                $('#wishlist_top .wrap_tot_panier').remove();
                $('#wishlist_top #cart_list').remove();
                $('#wishlist_top #show_top_cart').addClass('noprod');
                var newtxt = '<div class="no_product">' + Translator.translate('your_wishlist_empty');
                $('#wishlist_top #show_top_cart').append(newtxt);
            } else {
                $('#wishlist_top .title_panier .title_wishlist_rollover .nb_products').text('(' + (wishlistLength));
                $('#wishlist_list .holder_scrollable').overlayScrollbars({
                    scrollbars: {
                        autoHide: "leave",
                        autoHideDelay: 0,
                    }
                });

            }
        }
    });
}

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    if (array_wishlist_product_ids.length > 0 && array_wishlist_product_ids.length < 2) {
        // If there is only one product to add in bakset add loading class to its add btn
        if ($('#wproduct_' + array_wishlist_product_ids[0]).length && $('#wproduct_' + array_wishlist_product_ids[0] + ' .cta_add_to_basket .loader').length) {
            $('#wproduct_' + array_wishlist_product_ids[0] + ' .cta_add_to_basket .loader').addClass('loading');
        }
    }

    $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: array_wishlist_product_ids.join(",")}, function(resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}