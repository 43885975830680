$(function (){

    if (!localStorage.getItem('changePricing')) {
        $.ajax({
            url: path_relative_root + 'ajax_tarification_list.php',
            type: 'post',
            dataType: 'json',
            data: {countryByIp: true},
            success: function (res) {
                if (res !== []) {
                    var country_iso = '';

                    $.each(res, function (key, val) {
                        //Récupération de l'iso pays selectionné, ici par rapport a l'ip
                        if (val.selected) {
                            country_iso = val.iso3;
                        }
                    });

                    // On va envoyer au serveur que l'on change de pays
                    $.ajax({
                        url: path_relative_root + "ajax_tarification_change_country.php",
                        type: 'post',
                        data: 'iso=' + country_iso,
                        success: function () {
                            localStorage.setItem('changePricing', 'true');
                            location.reload(); // Refresh the page

                        }
                    });
                }
            }
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});
// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartDesappear() {
    var cart_top = $('#cart_top');
    cart_top.removeClass('active_rollover')

    $('#shad_menu').removeClass("actif");
}

function btnLoaderUnloading(btn) {
    "use strict";
    var loader = btn.next(".loader");

    if (loader.length > 0) {
        setTimeout(function () {
            loader.removeAttr("style").parent().removeClass("loading")
        }, 200);
    }
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

function checkSelectedInput(){
    var inputTaille = $('.product_infos_form.not_achat_express').find('#tailleProd');
    if (inputTaille.length && inputTaille.val() == '') {
        $('#sizeerror').slideDown();
    }
}